<template>
  <div>
    <v-card outlined class="my-5 mt-15">
      <v-card-text class="profilAgencyAvatar">
        <v-card max-width="fit-content" color="EoleBlue" class="rounded-pill pr-2" flat>
          <v-card-title style="color: white !important; display: inline-block">
            <i class="fa-solid fa-building mr-2 ml-2" style="font-size: 1.75rem"></i>
            DUE DE LA SOCIÉTÉ {{ customer.commercial_name }}
          </v-card-title>
        </v-card>
      </v-card-text>
      <v-card-text class="mt-16">
        <v-row v-if="!!customer">
          <v-col cols="12" md="6" class="text-left">
            <strong>Nom commercial :</strong> {{ customer.commercial_name }} <br />
            <strong>Code NAF/APE :</strong> {{ customer.naf_code }} <br />
            <strong>Libellé du code NAF :</strong> {{ customer.naf_libelle }} <br />
          </v-col>
          <v-col cols="12" md="6" class="text-right">
            <strong> Numéro de siret : </strong> {{ customer.siret }} <br />
            <v-spacer> </v-spacer>
            <h3>
              <a @click="showMap(`${customer.address}, ${customer.postal_code}, ${customer.city}`)">
                {{ customer.address }}<br />
                {{ customer.postal_code }}, {{ customer.city }}
              </a>
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="mt-10">
      <v-col v-for="item in customer.due_items" :key="item.id">
        <v-card class="rounded-xl align-center justify-center" height="220px" :disabled="!item.isGenerate">
          <v-row class="mr-1">
            <v-col cols="12" class="text-right">
              <v-menu right transition="scale-transition" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    style=""
                    class="justify-center text-center EoleBlue white--text ml-3 align-center btn-add-module"
                    x-small
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-subheader class="ml-2">Réaliser une action</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item link color="EoleBlue">
                    <v-list-item-action>
                      <i class="fa-solid fa-download"></i>
                    </v-list-item-action>
                    <v-list-item-content @click="downloadFile(item.documentUrlComment)">
                      <v-list-item-title> Télécharger la DUE commentée </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item link color="EoleBlue">
                    <v-list-item-action>
                      <i class="fa-solid fa-download"></i>
                    </v-list-item-action>
                    <v-list-item-content @click="downloadFile(item.documentUrlShareable)">
                      <v-list-item-title> Télécharger la DUE diffusable </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item link color="EoleBlue">
                    <v-list-item-action>
                      <i class="fa-solid fa-file-invoice"></i>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Télécharger la facture </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item link color="EoleBlue">
                    <v-list-item-action>
                      <i class="fa-solid fa-edit"></i>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Modifier la DUE </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="text-center justify-center mx-auto">
            <v-col cols="12" class="text-center">
              <v-card-text>
                <i :class="[getIcon(item), 'fa-5x', `${item.color}--text`]"></i>
              </v-card-text>

              <v-card-text> {{ item.name }} {{ item.category }} </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css"
import config from "@/views/config/config"
import { getCookie } from "@/utils/cookies"

export default {
  name: "CustomerDue",
  data: () => ({
    customer: null,
    due_items: [
      {
        id: 1,
        name: "Santé",
        id_customer_professionnal_due_type: 1,
        id_customer_professionnal_due_category: 1,
        category: "Cadre",
        isGenerate: false,
        sigle: "SC",
        color: "EoleBlue",
        disabled: false,
      },

      {
        id: 2,
        name: "Santé",
        id_customer_professionnal_due_type: 2,
        id_customer_professionnal_due_category: 1,
        category: "Non cadre",
        isGenerate: false,
        sigle: "SNC",
        color: "EoleBlue",
        disabled: true,
      },
      {
        id: 3,
        name: "Prévoyance",
        id_customer_professionnal_due_type: 1,
        id_customer_professionnal_due_category: 2,
        category: "Cadre",
        isGenerate: false,
        sigle: "PC",
        color: "EoleYellow",
        disabled: true,
      },
      {
        id: 4,
        name: "Prévoyance",
        id_customer_professionnal_due_type: 2,
        id_customer_professionnal_due_category: 2,
        category: "Non cadre",
        isGenerate: false,
        sigle: "PNC",
        color: "EoleYellow",
      },
      {
        id: 5,
        name: "Retraite",
        id_customer_professionnal_due_type: 1,
        id_customer_professionnal_due_category: 3,
        category: "Cadre",
        isGenerate: false,
        sigle: "RC",
        color: "EoleGray",
      },
      {
        id: 6,
        name: "Retraite",
        id_customer_professionnal_due_type: 2,
        id_customer_professionnal_due_category: 3,
        category: "Non cadre",
        isGenerate: false,
        sigle: "RNC",
        color: "EoleGray",
      },
    ],
  }),
  async created() {
    const id = this.$route.params.id
    await this.fetchCustomerById(id)
  },
  methods: {
    async downloadFile(path) {
      const idAccount = this.$store.state.user.id
      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/documents/preview`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({ url: path }),
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.blob() // Récupère le blob du fichier
        const downloadUrl = URL.createObjectURL(data) // Crée une URL pour le blob

        // Créer un élément <a> pour déclencher le téléchargement
        const a = document.createElement("a")
        a.href = downloadUrl
        a.download = path.split("/").pop() // Utilise le nom du fichier dans l'URL ou définissez un nom spécifique
        document.body.appendChild(a)
        a.click()

        // Nettoyage: retire l'élément <a> et libère l'URL objet
        document.body.removeChild(a)
        window.URL.revokeObjectURL(downloadUrl)
      } catch (error) {
        console.error("Error downloading the file:", error)
      }
    },
    async fetchCustomerById(id) {
      const idAgency = this.$store.state.user.agency_id
      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/customers/siret/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        if (response.ok) {
          const data = await response.json()
          this.customer = data

          // Mettre à jour due_items avec l'état de génération
          const updatedDueItems = this.due_items.map(item => {
            const generatedItem = this.customer.due_items.find(
              apiItem =>
                apiItem.id_customer_professionnal_due_category === item.id_customer_professionnal_due_category &&
                apiItem.id_customer_professionnal_due_type === item.id_customer_professionnal_due_type,
            )
            return {
              ...item,
              isGenerate: !!generatedItem,
              documentUrlComment: generatedItem ? generatedItem.url_document_comment : null,
              documentUrlShareable: generatedItem ? generatedItem.url_document_shareable : null,
            }
          })

          this.customer.due_items = updatedDueItems
        }
      } catch (error) {
        console.log(error)
      }
    },
    getIcon(item) {
      switch (item.id) {
        case 1:
        case 2:
          return "fa-solid fa-heart"
        case 3:
        case 4:
          return "fa-solid fa-umbrella"
        case 5:
        case 6:
          return "fa-solid fa-wallet"
        default:
          return "fa-solid fa-file"
      }
    },
    showMap(address) {
      window.open(`https://www.google.com/maps?q=${address}`, "_blank")
    },
  },
}
</script>

<style lang="scss" scoped>
.mullerFont {
  font-family: muller;
  font-size: 1.4rem;
  font-weight: 200;
}

.div-block {
  display: inline-block;

  margin-right: 10px;
  margin-left: 10px;
}

.v-text-field {
  text-align: center;
  margin-right: 10px;
  line-height: 1.5rem;
  padding: 0.375rem 0.875rem;
}

@media screen and (min-width: 2000px) {
  .profilAgencyAvatar {
    position: absolute;
    top: 200px;
    z-index: 0;
  }
}

@media screen and (min-width: 1500px) {
  .profilAgencyAvatar {
    position: absolute;
    top: -57px;
    z-index: 0;
  }
}

@media screen and (max-width: 1500px) {
  .profilAgencyAvatar {
    position: absolute;
    top: -50px;
    z-index: 0;
  }
}

.font-due-tab {
  font-size: 0.7rem;
}
</style>
